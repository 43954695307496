<template>
  <ul class="menu-nav">
    <router-link
      :to="'/' + slug + '/dashboard'"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Accueil </span>
        </a>
      </li>
    </router-link>
    <router-link
      :to="'/' + slug + '/games'"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        v-show="currentUser.role === 0"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Tous les jeux</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-show="currentUser.role === 0"
      :to="'/' + slug + '/com-center'"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Com Center</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTMenu",
  data() {
    return {
      slug: ""
    };
  },
  mounted() {
    this.slug =
      this.$route.params.pathMatch.split("/")[0] ||
      this.$route.params.pathMatch.replace(/^\/|\/$/g, "");
    console.log(this.slug);
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
